@use 'mixins';

.TagOutro {
    margin: var(--largePadding) 0;
    padding: var(--largePadding);
    padding-top: 0;
    background: rgb(var(--lightSectionBack));
    font-size: 1em;
    line-height: 1.5em;
    color: rgb(var(--lighterTextColor));
    border-radius: var(--standardRadius);
    transition: max-height 0.7s ease-in-out;
    white-space: pre-line;

    .background {
        position: relative;
        width: 100%;
        padding-top: var(--hugePadding);
        margin-top: -70px;
        background-image: linear-gradient(to top, rgba(244, 244, 244, 1), rgba(244, 244, 244, 0.8));

        button {
            display: block;
            padding: var(--standardPadding) var(--largePadding);
            margin: 0 auto;
            background-color: rgb(var(--invertedBackground));
            color: rgb(var(--invertedTextColor));
            border: none;

            &:hover {
                background-color: rgb(var(--secondColor));
            }
        }
    }

    h2 {
        padding-top: var(--largePadding);
        padding-bottom: var(--tinyPadding);
        font-size: 1.5em;
    }

    p {
        margin-top: 0.5em;
        font-size: 1.125em;
        line-height: 1.5em;
    }

    .default {
        max-height: 30em;
        width: 100%;
        overflow: hidden;
    }

    .load-more {
        overflow: auto;
    }

    .is-shown {
        display: none;
    }
}
